import React, { useState } from 'react';
import PropTypes from 'prop-types';

import TableOfContents from '@rocketseat/gatsby-theme-docs/src/components/Docs/TOC';
import Sidebar from '@rocketseat/gatsby-theme-docs/src/components/Sidebar';
import Header from '@rocketseat/gatsby-theme-docs/src/components/Header';
import { Wrapper, Main, Title, Children } from '@rocketseat/gatsby-theme-docs/src/components/Layout/styles';
import Login from "./Login";
import Logout from "./Logout"
import { Auth0Provider } from "@auth0/auth0-react";

export default function Layout({
  children,
  disableTableOfContents,
  title,
  headings,
}) {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const disableTOC =
    disableTableOfContents === true || !headings || headings.length === 0;

  function handleMenuOpen() {
    setMenuOpen(!isMenuOpen);
  }

  return (
    <>
      <Auth0Provider
        domain={process.env.GATSBY_OAUTH_DOMAIN}
        clientId={process.env.GATSBY_OAUTH_CLIENT_ID}
        redirectUri={process.env.GATSBY_OAUTH_REDIRECT}
        useRefreshTokens={true}
        cacheLocation="localstorage"      
      >
        <Login>
          <Sidebar isMenuOpen={isMenuOpen} />
          <Header handleMenuOpen={handleMenuOpen} isMenuOpen={isMenuOpen} />
          <Logout />
          <Wrapper isMenuOpen={isMenuOpen}>
            {title && <Title>{title}</Title>}
            <Main disableTOC={disableTOC}>
              {!disableTOC && <TableOfContents headings={headings} />}
              <Children hasTitle={title}>{children}</Children>
            </Main>
          </Wrapper>
        </Login>
      </Auth0Provider>
    </>
  );
}

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  disableTableOfContents: PropTypes.bool,
  title: PropTypes.string,
  headings: PropTypes.array,
};

Layout.defaultProps = {
  disableTableOfContents: false,
  title: '',
  headings: null,
};