/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from "react";
import { withAuth0 } from '@auth0/auth0-react';

class Login extends React.Component {
  checkCurrentUser = () => {
    const {
      isAuthenticated,
      loginWithRedirect,
      isLoading,
    } = this.props.auth0;

    if (isLoading) {
      return
    }

    if (!isAuthenticated) {
      loginWithRedirect()
    }
  }
  
  componentDidUpdate() {
    this.checkCurrentUser();
  }
  
  render() {
    const { children } = this.props;
    const {
      isAuthenticated,
      isLoading
    } = this.props.auth0;

    return isAuthenticated || isLoading ? children : <div/>
  }
}

export default withAuth0(Login);
