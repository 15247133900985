/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import React from "react";
import { withAuth0 } from '@auth0/auth0-react';

class Logout extends React.Component {
    render() {
        const {
            logout
        } = this.props.auth0;

        return (
            <a style={{position:"absolute", cursor:"pointer", right:"30px", top:"30px"}} 
                onClick={() => logout({ returnTo: process.env.GATSBY_OAUTH_REDIRECT })}>
                Logout
            </a>
        );    
    }
}
  
export default withAuth0(Logout);
